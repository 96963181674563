$app-base-primary-color: #0076a8;
.page-container-title {
  color: #1565c0;
  font-size: 1.5rem;
}
.page-container-title-mini {
  color: #1565c0;
  font-size: 1rem;
}
#mainContent {
  min-height: calc(100vh - 165px);

  .custom-control-label {
    font-weight: normal !important;
  }
  // box-shadow: 0px 0px 0px 0px rgba(66, 66, 66, 0.1), 0px 0px 5px 5px rgba(0, 0, 0, 0.0666);
  //background-color: #e6eaf0;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;

  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

table {
  thead {
    background: #f8f8f8;
    padding: 10px;
    // color: #fff;
  }
}

.customized-table {
  font-size: 12px !important;
  box-shadow: 0 2px 4px 0 #eeeeee;
  .btn {
    font-size: 12px !important;
  }
  .form-control {
    height: calc(1em + 0.7rem) !important;
    font-size: 12px !important;
    text-align: center;
    padding-top: 3px !important;
    min-width: 90px;
    width: auto;
    padding: 4px;
  }
  td,
  tr {
    vertical-align: middle !important;
    text-align: center;
  }
  td {
    white-space: nowrap;
  }

  tbody tr {
    background-color: #fff;
  }
  .pointer {
    cursor: pointer;
  }
}

.notification-item {
  .form-group {
    background-color: #e9ecef;
    padding: 5px 10px;
    margin-bottom: 0.6rem;
  }
  .notification-item__label {
    // font-weight: bold;
    color: gray;
  }
  .notification-item__value {
    overflow: auto;
    max-height: 250px;
    padding-right: 5px;
    &.notification__critical {
      color: #b02e0c;
    }
    &.notification__immediate {
      color: #f42b03;
    }
    &.notification__standard {
      color: #fb7ba2;
    }
    &.notification__actioned {
      color: #007a05;
    }
  }
}
.notification__critical {
  color: #b02e0c;
}
.notification__immediate {
  color: #f42b03;
}
.notification__standard {
  color: #fb7ba2;
}
.notification__actioned {
  color: #007a05;
}

/*** bootstrap overrides ***/

.form-control:focus {
  box-shadow: 1px 1px 6px 0rem rgba(33, 150, 243, 0.25);
}

.modal-content {
  border: 0;
  box-shadow: 0px 0px 14px 0px #313435;
}

fieldset {
  border: 1px solid #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
  border-radius: 4px;
}

legend {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

.dayrate-availability-date-inputs {
  width: 100px;
}

.mappings-headings {
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 20px auto;
}
.mappings-sub-headings {
  color: #000;
  font-weight: 600;
  font-size: 1.25rem;
}
.sub-response-container {
  width: 90%;
  margin: auto;
}
.rateplan-name {
  color: #000;
  font-weight: 600;
  font-size: 1.25rem;
}
.rateplan-not-mapped {
  color: #000;
  font-weight: 400;
  margin-left: 10px;
}

.custom-popup-window-full {
  min-width: calc(100vw - 40px);
}

.custom-popup-window-75-percent {
  min-width: calc(75vw - 40px);
}

.btn-link {
  padding: 0;
}

// to hide arrows from number input
.input-number-hide-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.table-collapse-animation {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.table-collapse-animation.down {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.table-sort-animation {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.table-sort-animation.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fontweight-400 {
  font-weight: 400 !important;
}

.modal-max-width-90 {
  max-width: 90% !important;
}

.bg-grey {
  background-color: #f8f8f8 !important;
}
.bg-dark-grey {
  background-color: darkgray !important;
}

.color-legend-box {
  width: 15px;
  height: 15px;
}

.w-75px {
  width: 75px;
}
.w-90px {
  width: 90px;
}

.ml-20px {
  margin-left: 20px;
}

/*********** core css *********/

.cursor-pointer {
  cursor: pointer !important;
}

.CheckIn-CheckOut-DateInputs {
  width: 120px;
}

button:disabled {
  cursor: not-allowed;
}

.red {
  color: red;
}

.stop-overlay {
  .custom-control {
    z-index: 0;
  }
}

.react-datepicker-wrapper {
  display: block;

  .react-datepicker__input-container::after {
    content: "\e6b6";

    font-family: "themify";
    position: absolute;
    right: 8px;
    top: 6px;
  }
}

/*   common css */

.table-with-scrollable-height {
  max-height: calc(100vh - 190px);
}
.max-width-150px {
  max-width: 150px;
}
.margin-auto {
  margin: auto;
}
