// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Background Size
// + @Background Position
// + @Background Repeat
// + @Object Fit
// + @Resize
// + @Opacity
// + @Cursor
// + @Visibility

// ---------------------------------------------------------
// @Background Size
// ---------------------------------------------------------

.bgsz-cv   { background-size: cover; }
.bgsz-ct   { background-size: contain; }
.bgsz-full { background-size: 100% 100%; }

// ---------------------------------------------------------
// @Background Position
// ---------------------------------------------------------

.bgpX-c    { background-position-x: center; }
.bgpX-t    { background-position-x: top; }
.bgpX-r    { background-position-x: right; }
.bgpX-l    { background-position-x: left; }
.bgpX-b    { background-position-x: bottom; }
.bgpY-c    { background-position-y: center; }
.bgpY-t    { background-position-y: top; }
.bgpY-r    { background-position-y: right; }
.bgpY-l    { background-position-y: left; }
.bgpY-b    { background-position-y: bottom; }

// ---------------------------------------------------------
// @Background Repeat
// ---------------------------------------------------------

.bgr-n     { background-repeat: no-repeat; }
.bgr-y     { background-repeat: repeat-y; }
.bgr-x     { background-repeat: repeat-x; }

// ---------------------------------------------------------
// @Object Fit
// ---------------------------------------------------------

.of-ct     { object-fit: contain; }
.of-cv     { object-fit: cover; }
.of-f      { object-fit: fill; }
.of-n      { object-fit: none; }
.of-sd     { object-fit: scale-down; }

// ---------------------------------------------------------
// @Resize
// ---------------------------------------------------------

.rsz-v     { resize: vertical; }
.rsz-h     { resize: horizontal; }

// ---------------------------------------------------------
// @Opacity
// ---------------------------------------------------------

.op-0      { opacity: 0; }
.op-10p    { opacity: 0.1; }
.op-20p    { opacity: 0.2; }
.op-30p    { opacity: 0.3; }
.op-40p    { opacity: 0.4; }
.op-50p    { opacity: 0.5; }
.op-60p    { opacity: 0.6; }
.op-70p    { opacity: 0.7; }
.op-80p    { opacity: 0.8; }
.op-90p    { opacity: 0.9; }
.op-100p   { opacity: 1; }

// ---------------------------------------------------------
// @Cursor
// ---------------------------------------------------------

.cur-na    { cursor: not-allowed; }
.cur-p     { cursor: pointer; }
.cur-a     { cursor: auto; }

// ---------------------------------------------------------
// @Visibility
// ---------------------------------------------------------

.vis-v     { visibility: visible; }
.vis-h     { visibility: hidden; }
