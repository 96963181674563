.sb-nav-container {
  padding: 10px;
}

.sb-nav-container:hover {
  overflow: auto;
  padding-right: 1px;
}

.sb-nav-title {
  font-weight: bold;
}

.sb-nav-container-inner {
  padding: 0 0 15px;
  // background: #f8f9fa;
  border-radius: 5px;
}

.customized-link-nav:hover {
  text-decoration: underline;
}

.customized-link-nav-active {
  display: flex;
  font-weight: 600;
  background: azure;
}

.sidebar-collapse-back {
  right: -206px;
  position: absolute;
  top: 0px;
}
