.pegs-container {
  .property-label {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .no-data-card {
    max-width: 600px;
    margin: 0 auto;
  }

  .pegs-form {
    display: grid;
    max-width: 600px;
    margin: 1rem auto;
    label {
      font-size: 1rem;
      margin-bottom: 0.2rem;
      text-align: center;
    }

    input {
      text-align: center;
      font-size: 1rem;
      padding: 0.5rem 2rem;
      line-height: 1rem;
      border-radius: 20px;
      border: 1px solid #ddd;
      margin-bottom: 1rem;
    }

    button {
      background-color: rgba(0, 0, 0, 0.75);
      color: white;
      padding: 0.4rem 2rem;
      border: 1px solid rgba(0, 0, 0, 0.75);
      border-radius: 20px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 250ms;
    }

    button:hover {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
  @media (min-width: 600px) {
    .pegs-form {
      grid-template-columns: auto 1fr auto;
      grid-gap: 1rem;
      align-items: center;
      input {
        margin-bottom: 0;
      }
    }
  }
}
