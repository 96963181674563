.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  min-width: 150px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border: 1px solid #ced4da;
  background-color: #fff;
  // font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  z-index: 2;
  width: 100%;
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
  line-height: 1.2;
}

.react-autosuggest__suggestion strong {
  font-size: 0.75rem;
}

.react-autosuggest__suggestion b {
  color: #444;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.prop-auto-complete-spinner {
  position: absolute;
  bottom: 9px;
  right: 12px;
}

.prop-auto-complete-container {
  position: relative;
}
